import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../Layout'
import {useTranslation} from "gatsby-plugin-react-i18next";

import Box from '@mui/material/Box';

const ContactPage = ({location }) => {
    const {t} = useTranslation();

    return (
        <Layout type="full" htmlClassName="contact" title={t('h1')} pathname={location.pathname}>
            <Box sx={{maxWidth:1000,margin:"0 auto",textAlign:"center"}}>
                <Box component='h1' sx={{fontSize:50,mt:{xs:5,sm:10},mb:1}}>{t('h1')}</Box>
                <Box component="p" sx={{lineHeight: 1.8}}>
                    {t('desc')} <a href="mailto://support@mtmt.tech">support@mtmt.tech</a>
                </Box>

            </Box>

        </Layout>
    )
}

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;